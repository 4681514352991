.headerr{
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 100px;
}

.innerheader{
    position: relative;
    top: 0px;
}

.innerheader > h2{
    color: white;
}

.status-message{
    font-size: 13px;
}

.headerr > h2{
    text-align: center;
    color: white;
}

/* .headerr{
    background: rgb(255, 97, 194);
} */

p{
    text-align: center;
}