.headerr{
  height: 100vh;
}

@import url("https://fonts.googleapis.com/css2?family=Matemasie&display=swap");


h2{
  text-align: center;
  margin-top: 100px;
  font-family: "Matemasie", sans-serif;
}
/* 
input{
  width: 100%;
  max-width: 300px;
} */

select{
  width:auto;
  margin:20px;
  background-color: white;
  border: none;
  padding-top: 20px;
  font-size: 17px;
  text-align: center;
  border-radius: 20px;
  font-family: "Matemasie", sans-serif;
}

option{
  font-family: "Matemasie", sans-serif;
}

label{
  margin: 9px;
  font-family: "Matemasie", sans-serif;
  color:white;
}

p{
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  
}
/* .processing-message {
  color: #007bff;
  font-size: 19px;
  margin-top: 10px;
  margin-bottom: 10px;
} */


.form{
    background-color: rgb(90, 0, 63);
    width: 400px;
    max-width: 450px;
    height: 100%;
   /* margin: auto; */
    text-align: center;
    border-radius: 20px;
    padding-top: 90px;
    margin-bottom: 70px;
    padding-bottom: 90px;
   
}

textarea{
  border-radius: 5px;
  border: none;
  text-align: center;
  width: 300px;
  max-width: 400px;

}

.btn{
  color: white;
}

.btn-whatsapp {
  background-color: #25D366; /* WhatsApp green color */
  color: white;
  font-weight: 700;
  border: none;
  margin: auto;
  width: 200px;
  margin-top: 30px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.btn-whatsapp:hover {
  background-color: #128C7E; /* Darker green color on hover */
}


@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

input{
  border-radius: 5px;
  border: none;
  width: 300px;
  margin: auto;
  padding: 20px;
  max-width: 300px;
  color: black;
  font-family: "Matemasie", sans-serif;
}

@media (max-width:500px) {

  
  .form{
    width: 300px;
    max-width: 350px;
  }
  

  .select{
    width:200px;
    margin:20px;
    background-color: white;
    border: none;
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    font-family: "Matemasie", sans-serif;
  }
  

  
  .option{
    font-family: "Matemasie", sans-serif;
  }



  input{
    width: 270px;
    height: 80px;
    margin: 50px;
    text-align: center;
    color: black;
  }

  textarea{
    width: 200px;
  }
}