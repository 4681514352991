/* Services page specific styles */



@import url("https://fonts.googleapis.com/css2?family=Matemasie&display=swap");

.body{
  height: 100vh;
}
.services-page {
    padding: 50px 0;
  }
  
  .services-page h2 {
    color: rgb(255, 255, 255);
    font-weight: 800;
    margin-bottom: 20px;
    font-family: "Matemasie", sans-serif;
  }
  
  .services-page p {
    margin-bottom: 30px;
    font-size: 1.2rem;
    
  }
  
  .cardd {
    overflow: hidden;


  }
  
  .card-img-top {
    width: 200px;
    margin: auto;
    padding: 20px;
    border-radius: 50px;
    height: 300px; /* Adjust based on your images */

 
  }

  .card-body{
    margin: auto;
    width: 100%;
    max-width: 300px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px;
  }
  
  .card-title {
    font-size: 18px;
    font-family: "Matemasie", sans-serif;
    color: rgb(0, 0, 0);
    text-align: center;
    font-weight: 400;
  }
  
  .card-text {
    font-size: 10px;
    text-align: center;
    font-family: 'Georgia', serif; /* Example serif font */
    color: #000000; /* Adjust color based on theme */
  }

  .button {
    position: relative;
    top: 70px;
    padding: 10px 22px;
    border-radius: 6px;
    border: none;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    background-color: #6e0035;
    transition: all 0.2s ease;
  }
  
  .button:active {
    transform: scale(0.96);
  }
  
  .button:before,
  .button:after {
    position: absolute;
    content: "";
    width: 150%;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    z-index: -1000;
    color: #fff;
    text-decoration: none;
    background-repeat: no-repeat;
  }
  
  .button:hover:before {
    color: #fff;
    text-decoration: none;
    top: -70%;
    background-image: radial-gradient(circle, #ffffff 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, #ffffff 20%, transparent 30%),
      radial-gradient(circle, #ffffff 20%, transparent 20%),
      radial-gradient(circle, #e82a96 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
      radial-gradient(circle, #8a64bc 20%, transparent 20%),
      radial-gradient(circle, #ffffff 20%, transparent 20%),
      radial-gradient(circle, #e82ac2 20%, transparent 20%),
      radial-gradient(circle, #e82aa2 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
      10% 10%, 18% 18%;
    background-position: 50% 120%;
    animation: greentopBubbles 0.6s ease;
  }
  
  @keyframes greentopBubbles {
    0% {
      background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
        40% 90%, 55% 90%, 70% 90%;
    }
  
    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
        50% 50%, 65% 20%, 90% 30%;
    }
  
    100% {
      background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
        50% 40%, 65% 10%, 90% 20%;
      background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
  }
  
  .button:hover::after {
    color: #fff;
    text-decoration: none;
    bottom: -70%;
    background-image: radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
    background-position: 50% 0%;
    animation: greenbottomBubbles 0.6s ease;
  }
  
  @keyframes greenbottomBubbles {
    0% {
      background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
        70% -10%, 70% 0%;
    }
  
    50% {
      background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
        105% 0%;
    }
  
    100% {
      background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
        110% 10%;
      background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
  }

  .services-page {
    padding: 20px;
  }
  
  .cardd {
    border: none;
  }

  .buttonnn{
    background-color: purple;
    padding: 10px;
    position: relative;
    top: 20px;
    border-radius: 20px;
    color: white;
    text-decoration: none;
    font-family: "Matemasie", sans-serif;
  }

  .buttonn{
    background-color: purple;
    padding: 10px;
    position: relative;
    top: -20px;
    border-radius: 20px;
    color: white;
    text-decoration: none;
    font-family: "Matemasie", sans-serif;
  }
  
  .cardimg {
    height: 200px;
    object-fit: cover;
  }
  
  .d-flex {
    display: flex;
    align-items: center;
  }
  
  .flex-grow-1 {
    flex-grow: 1;
  }
  
  .price-table {
    margin-left: 20px;
    text-align: right;
    font-family: "Matemasie", sans-serif;
  }
  
  .price-table table {
    border-collapse: collapse;
  }
  
  .price-table td {
    padding: 5px;
    border: 1px solid #ddd;
  }
  

 
  