header {
  height: 80vh;
}





@import url("https://fonts.googleapis.com/css2?family=Matemasie&display=swap");

.hero-overlay h1 {
  text-align: center;
  font-family: "Matemasie", sans-serif;
  font-weight: 400;
  color: rgb(192, 0, 134);
  font-size: 30px;
  margin-top: 100px;
}

.buttonnn {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: white;
}



.whatsapp-buttonn {
  background-color: #25D366; /* WhatsApp green color */
  margin-top: 10px;
  
}

.whatsapp-buttonn:hover {
  background-color: #128C7E; /* Darker green color on hover */
}

body {
  background: url(../images/massage-4817906.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
p{
    color: white;
    font-weight: 400;
    font-size: 29px;
    font-family: "Titillium Web", sans-serif;
}

.btnn{
  margin: 20px;
  font-size:18px;
  text-align: center;
  text-decoration: none;
  color: white;
  margin-bottom: 100px;
}
a{
  color: white;
  
}

a:hover{
  text-decoration: none;
  color: white;

}

a:active{
  text-decoration: none;
  color: white;
}
Link{
  text-decoration: none;
  color: white;
}

.buttonn{
  margin: auto;
  position: relative;
  top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.button {
    position: relative;
    top: 70px;
    padding: 10px 22px;
    border-radius: 6px;
    border: none;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    background-color: #6e0035;
    transition: all 0.2s ease;
  }
  
  .button:active {
    transform: scale(0.96);
    text-decoration: none;
    color: white;
  }
  
  .button:before,
  .button:after {
    position: absolute;
    
    content: "";
    width: 150%;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    z-index: -1000;
    color: #fff;
    text-decoration: none;
    background-repeat: no-repeat;
  }
  
  .button:hover:before {
    color: #fff;
    text-decoration: none;
    top: -70%;
    background-image: radial-gradient(circle, #ffffff 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, #ffffff 20%, transparent 30%),
      radial-gradient(circle, #ffffff 20%, transparent 20%),
      radial-gradient(circle, #e82a96 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
      radial-gradient(circle, #8a64bc 20%, transparent 20%),
      radial-gradient(circle, #ffffff 20%, transparent 20%),
      radial-gradient(circle, #e82ac2 20%, transparent 20%),
      radial-gradient(circle, #e82aa2 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
      10% 10%, 18% 18%;
    background-position: 50% 120%;
    animation: greentopBubbles 0.6s ease;
  }
  
  @keyframes greentopBubbles {
    0% {
      background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
        40% 90%, 55% 90%, 70% 90%;
    }
  
    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
        50% 50%, 65% 20%, 90% 30%;
    }
  
    100% {
      background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
        50% 40%, 65% 10%, 90% 20%;
      background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
  }
  
  .button:hover::after {
    color: #fff;
    text-decoration: none;
    bottom: -70%;
    background-image: radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
    background-position: 50% 0%;
    animation: greenbottomBubbles 0.6s ease;
  }
  
  @keyframes greenbottomBubbles {
    0% {
      background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
        70% -10%, 70% 0%;
    }
  
    50% {
      background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
        105% 0%;
    }
  
    100% {
      background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
        110% 10%;
      background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
  }


@media (min-width:1200px) {
    .hero-overlay h1 {
        text-align: center;
        font-family: "Matemasie", sans-serif;
        font-weight: 400;
        color: rgb(192, 0, 134);
        font-size: 70px;
        margin-top: 100px;
      }  
}
