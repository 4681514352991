footer{
    background-color: rgb(54, 0, 46);
    margin-top: 200px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
}

footer > a{
    text-decoration:none;
    color:white;
}

.mauto{
    display: flex;
    justify-content: center;
   align-content: space-evenly;
}
small{
    color: white;
    opacity: 30%;
}

i{
    color: white;
}

footer > h2 {
    color: white;
    /* font-family: 'Courier New', Courier, monospace; */
    font-size: 17px;
}

footer > .ul{
    list-style: none;
    text-decoration: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 24px;
}