/* General styling for smooth and elegant feel */
.body {
  background: linear-gradient(to right, #cf5b9b, #cb169b);
  font-family: 'Poppins', sans-serif;
  text-align:center;
  height: 80vh;
  margin: auto;
  animation: backgroundFade 10s infinite alternate;
}


form{
  margin:auto;
  }

@keyframes backgroundFade {
  0% { background: linear-gradient(to right, #dd4b9e, #df53b7); }
  100% { background: linear-gradient(to right, #d24fbc, #9b1071); }
}

.App {
  background: rgba(170, 38, 144, 0.7);
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: auto;
  width:100%;
  max-width: 400px;
  text-align: center;
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}

/* Logo animation */
.logo {
  border-radius: 50%;
  animation: logoPop 1.5s ease-in-out infinite alternate;
}

@keyframes logoPop {
  0% { transform: scale(1); }
  100% { transform: scale(1.1); }
}

/* Smooth transition for input focus */
input {
  border: 2px solid #f8bcb8;
  border-radius: 5px;

  color: black;

  height: 25px;

}
.btnn{
  width: 200px;
  position: relative;
  top: 0px;
  bottom: 0px;
  background-color: white;
  border-radius: 20px;
  border: none;
  color: black;
  font-weight: 500;
  padding: 10px;

}


label{
  color: white;
}

.p{
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
  width: 100%;
}

button:hover {
  background: #ff9490;
  box-shadow: 0 0 15px rgba(248, 188, 184, 0.5);
}

button:disabled {
  background: #ffd4d2;
  cursor: not-allowed;
}

button:active {
  transform: scale(0.98);
}

h1 {
  font-size: 1.8rem;
  color: #ff9490;
  margin-bottom: 20px;
  animation: textFadeIn 1.5s ease-in-out;
}

@keyframes textFadeIn {
  0% { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Sweet message text */
p {
  font-size: 1rem;
  color: #fae7e7;
  font-weight: 700;
  margin-bottom: 20px;
  opacity: 0;
  animation: fadeInMessage 1.5s forwards;
  animation-delay: 1.5s;
}

@keyframes fadeInMessage {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

h3 {
  color: #ff9490;
}