.success > h1{
    color: white;
}

.body{
    height: 100vh;
}
.success{
    height: 100vh;
    position: relative;
    top: 180px;
}