.about-container{
    margin: auto;
    padding: 50px;
    padding:20px;
}
.about-container > h2{
    text-align: center;
    color: white;
    font-weight: 700;
    margin-top: 100px;
}
.about-container > h3{
    text-align: center;
    color: white;
    font-weight: 700;
    margin-top: 100px;
}
.about-container > p{
    text-align: center;
    color: white;
  font-size: 16px;
}

.btnnn{
    position: relative;
    text-align: center;
    text-align: center;
    color: white;
    text-decoration: none;
}