@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
  
  .whatsapp-button {
    position: fixed;
    bottom: 20px; /* Adjust distance from the bottom */
    right: 20px;  /* Adjust distance from the right */
    background-color: #ffffff; /* WhatsApp green color */
    color: white;
    padding: 10px 20px;
    border-radius: 35px;
    font-size: 16px;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: bounce 2s infinite; /* Apply bounce animation */
  }
  
  .whatsapp-button:hover {
    background-color: #d5ffe4; /* Slightly darker green for hover effect */
  }
  